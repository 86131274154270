<template>
  <b-button
    :type="buttonType"
    class="th-button"
    @click="callButton"
    :disabled="buttonState"
    :size="buttonSize"
  >
    {{ buttonText }}
  </b-button>
</template>

<script>
export default {
  name: 'button-custom',
  props: {
    buttonText: {
      type: String,
      default: 'Default text',
    },
    buttonSize: {
      type: String,
      default: 'sm',
    },
    buttonState: {
      type: Boolean,
      default: false,
    },
    buttonType: {
      type: String,
      default: 'submit',
    },
  },
  methods: {
    callButton() {
      this.$emit('buttonEventEmit');
    },
  },
};
</script>

<style lang="scss" scoped>
.th-button {
  background-color: $btn-primary-custom;
  @include border-radius(31px);
  border: $none;
  height: 45px !important;
  @include space(padding, top, 13px);
  @include space(padding, bottom, 15px);
  @include font-source($openSans, 14px, $white, bold, 1.21px, normal);

  &:focus, &:hover {
    box-shadow: $none !important;
    @include property-color(background-color, #74b4ab)
  }

  &:active {
    @include property-color(background-color, #74b4ab)
  }
}
</style>
