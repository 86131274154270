import Vue from 'vue';
import Vuex from 'vuex';
import auth from './modules/auth.module';
import profile from './modules/profile.module';
import patients from './modules/patients.module';
import photos from './modules/photos.module';
import variable from '../core/helpers/variables/localVariables';

Vue.use(Vuex);

// Mutation types
export const COMMIT_LOADER = variable.LOADER.COMMIT_LOADER;
export const MUTATE_ELEMENT_COND_DURING_LOAD = variable.LOADER.COMMIT_LOADER;
export const SET_MODAL_ACTION = variable.MODAL.SET_MODAL_ACTION;
export const REMOVE_MODAL_ACTION = variable.MODAL.REMOVE_MODAL_ACTION;

// action types
export const SET_LOADER = variable.LOADER.LOADER_SET;
export const ELEMENT_COND_DURING_LOAD = variable.LOADER.ELEMENT_COND_DURING_LOAD;
export const MUTATE_MODAL = variable.MODAL.MUTATE_MODAL;
export const REMOVE_MUTATE_MODAL = variable.MODAL.REMOVE_MUTATE_MODAL;

export default new Vuex.Store({
  state: {
    // Current selected language for i18n
    currentSelectedLanguage: 'en',
    // Does data load
    doesDataLoad: null,
    nonVisibleLoad: false,
    modal: {
      current: null,
      type: null,
      params: null,
    },
    error: {
      title: null,
      message: null,
    },
  },
  getters: {
    getModal(state) {
      return state.modal;
    },
    elementCond(state) {
      return state.nonVisibleLoad;
    },
    getError(state) {
      return state.error;
    }
  },
  mutations: {
    [COMMIT_LOADER](state, data) {
      state.doesDataLoad = data;
    },
    [MUTATE_ELEMENT_COND_DURING_LOAD](state, data) {
      state.nonVisibleLoad = data;
    },
    [REMOVE_MUTATE_MODAL](state) {
      state.modal.current = null;
      state.modal.type = null;
      state.modal.params = null;
    },
    [MUTATE_MODAL](state, data) {
      state.modal.current = data.current;
      state.modal.type = data.type;
      state.modal.params = data.params;
    },
    SetErrorMutation(state, error) {
      if (error) {
        if (!error.message) {
          error.message = "Something went wrong. Please try again a bit later.";
        }
      }
      state.error = error;
    }
  },
  // plugins: [createPersistedState({
  //   paths: [
  //     'patients.selectedDoctorsArray',
  //     'patients.currentFilter.Status',
  //     'patients.currentFilter.UsersIds',
  //     'patients.currentFilter.PageNumber',
  //     'patients.currentSelectedPage',
  //     'patients.visitDetailFilter',
  //   ],
  // })],
  actions: {
    [SET_LOADER](context, data) {
      context.commit(COMMIT_LOADER, data);
    },
    [ELEMENT_COND_DURING_LOAD](context, data) {
      context.commit(MUTATE_ELEMENT_COND_DURING_LOAD, data);
    },
    [SET_MODAL_ACTION](context, data) {
      context.commit(MUTATE_MODAL, data);
    },
    [REMOVE_MODAL_ACTION](context) {
      context.commit(REMOVE_MUTATE_MODAL);
    },
    SetError(context, error) {
      context.commit('SetErrorMutation', error);
    },
  },
  modules: {
    auth,
    profile,
    patients,
    photos,
  },
});
