<template>
  <div v-if="showLoader" class="th-loader-wrp">
    <b-spinner class="th-loader-wrp--spinner" variant="success" label="Spinning"></b-spinner>
  </div>
</template>

<script>
export default {
  name: 'loader-elem',
  props: {
    showLoader: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
  .th-loader-wrp {
    @include calculatePosition(fixed, 0, 0, 0, 0);
    height: 100%;
    width: 100%;
    @include property-color(background-color, rgba(30, 45, 62, 0.5));
    display: flex;
    padding-top: 60%;
    justify-content: center;
  }
</style>
