<template>
  <div class="th">
      <router-view/>
      <loader-elem :show-loader="dataLoading" />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import LoaderElem from './components/atoms/loader.vue';

export default {
  components: { LoaderElem },
  computed: {
    ...mapState(
      {
        currentSelectedLanguage: (state) => state.currentSelectedLanguage,
        dataLoading: (state) => state.doesDataLoad,
      },
    ),
  },
};
</script>

<style lang="scss">
html, body {
  height: 100%;
  margin: 0;
  padding: 0;
}

/*html {
  .th {
    background-color: $primary !important;
  }
}*/

.th {
  height: 100%;

  & > div {
    &:first-child {
      height: 100%;
    }
  }
}
</style>
