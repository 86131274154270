const url = {
  REFRESH_TOKEN: 'LoginByRefreshToken',
  VERIFY_AUTH: 'verifyAuth',
  LOGIN: 'Login',
  SET_ERROR: 'setError',
  SET_USER: 'setUser',
  LOGOUT: 'LogOut',
  PURGE_AUTH: 'purgeAuth',
  FORGET_PASSWORD: 'ForgotOrChangePassword',
  GET_USER_DETAILS: 'GetUserDetails',
  GET_ALL_PATIENTS: 'FilterPatientsForDashboard',
  GET_ALL_USERS: 'getallusers',
  GET_DOCTOR_PATIENTS_SCANS_FLAGGEDDATA: 'GetUserInitialData',
  GET_VISIT_DETAILS: 'GetPatientVisitDetails',
  GET_PHOTO_DETAILS: 'GetPhotoDetails',
  EDIT_PHOTO_DETAILS: 'EditPhotoById',
  DELETE_PHOTO: 'DeletePhoto',
  EDIT_PATIENT_ID: 'UpdatePatientClinicId',
  DELETE_PATIENT: 'DeletePatient',
  REQUEST_BIOPSY_DATA_CHANGE: 'EditPatientVisitBiopsyData',
  EDIT_ADDITIONAL_DATA_CHANGE: 'EditPatientVisitRelatedData',
  EDIT_PATIENT_BI_RADS_DATA: 'EditPatientVisitBiRadsData',
  EDIT_PATIENT_VISIT_FLAGGED_DATA: 'EditPatientVisitFlaggedData',
  GET_PATIENT_VISIT_INIT_DATA: 'GetPatientVisitsForDashboard',
  SET_DATE_OF_BIRTH: 'SetDateOfBirth',
  ADD_DOCUMENT: 'AddDocument',
  DELETE_DOCUMENT: 'DeleteDocument',
  ACCEPT_PRIVACY: 'AcceptPrivacy',
  ADD_PHOTO_ANNOTATION:"AddPhotoAnnotation",
  GET_PHOTO_ANNOTATIONS:"GetPhotoAnnotations",
  DELETE_PHOTO_ANNOTATION:"DeletePhotoAnnotation"
};

export default url;
