import Vue from 'vue';
import apiService from '../../core/services/api.services';
import variable from '../../core/helpers/variables/localVariables';
import PatientTableStatsClass from '../../core/helpers/variables/patientTableStatsClass';
import PatientClassCallObject from '../../core/helpers/variables/patientClassCallObject';
import url from '../../core/helpers/variables/urlHelper';

// action types
export const GET_ALL_PATIENTS = url.GET_ALL_PATIENTS;
export const GET_ALL_USERS = url.GET_ALL_USERS;
export const PURGE_PATIENT_ARRAY = variable.PATIENT.PURGE_ARRAY;
export const REMOVE_CURRENT_PATIENT = variable.PATIENT.REMOVE_CURRENT_PATIENT;
export const GET_VISIT_DETAILS = url.GET_VISIT_DETAILS;
export const REMOVE_VISIT_DETAILS = variable.PATIENT.REMOVE_VISIT_DETAILS;
export const SET_INITIAL_BIOPSY_DATA = variable.PATIENT.SET_INITIAL_BIOPSY_DATA;
export const SET_INITIAL_BI_RADS_DATA = variable.PATIENT.SET_INITIAL_BI_RADS_DATA;
export const SELECTED_DOCTOR_ARRAY = variable.DOCTOR.SELECTED_DOCTOR_ARRAY;
export const REMOVE_SELECTED_DOCTOR_ARRAY = variable.DOCTOR.REMOVE_SELECTED_DOCTOR_ARRAY;
export const EDIT_PATIENT_ID = url.EDIT_PATIENT_ID;
export const DELETE_PATIENT = url.DELETE_PATIENT;
export const REQUEST_BIOPSY_DATA_CHANGE = url.REQUEST_BIOPSY_DATA_CHANGE;
export const EDIT_ADDITIONAL_DATA_CHANGE = url.EDIT_ADDITIONAL_DATA_CHANGE;
export const EDIT_PATIENT_VISIT_FLAGGED_DATA = url.EDIT_PATIENT_VISIT_FLAGGED_DATA;
export const EDIT_PATIENT_BI_RADS_DATA = url.EDIT_PATIENT_BI_RADS_DATA;
export const SET_DATE_OF_BIRTH = url.SET_DATE_OF_BIRTH;
export const GET_PATIENT_VISIT_INIT_DATA = url.GET_PATIENT_VISIT_INIT_DATA;
export const VISIT_DETAIL_FILTER_ACTION = variable.PATIENT.VISIT_DETAIL_FILTER_ACTION;
export const SET_CURRENT_PAGE = variable.PATIENT.SET_CURRENT_PAGE;
export const ADD_DOCUMENT = url.ADD_DOCUMENT;
export const DELETE_DOCUMENT = url.DELETE_DOCUMENT;
export const EDIT_PATIENT_VISIT_FLAGGED_DATA_ACTION = variable.PATIENT.EDIT_PATIENT_VISIT_FLAGGED_DATA_ACTION;
// mutation type
export const MUTATE_EDIT_PATIENT_ID = variable.PATIENT.SET_PATIENT_EDITED_ID;
export const MUT_VISIT_DETAIL_FILTER_ACTION = variable.PATIENT.MUT_VISIT_DETAIL_FILTER_ACTION;
export const SELECT_PATIENT_PROFILE = variable.PATIENT.SET_PATIENT;
export const SET_PATIENT_ARRAY = variable.PATIENT.SET_PATIENTS_ARRAY;
export const SET_DOCTOR_ARRAY = variable.DOCTOR.SET_DOCTOR_ARRAY;
export const REMOVE_DOCTOR_ARRAY = variable.DOCTOR.REMOVE_DOCTOR_ARRAY;
export const REMOVE_PATIENTS_ARRAY = variable.PATIENT.REMOVE_PATIENTS_ARRAY;
export const REMOVE_SELECTED_PATIENT = variable.PATIENT.REMOVE_PATIENT;
export const SET_TABLE_STAT = variable.TABLE.SET_TABLE_STAT;
export const REMOVE_TABLE_STAT = variable.TABLE.REMOVE_TABLE_STAT;
export const SET_FILTER_STAT = variable.FILTER.SET_FILTER_STAT;
export const REMOVE_FILTER_STAT = variable.FILTER.REMOVE_FILTER_STAT;
export const REMOVE_MUTATTION_PATIENT = variable.PATIENT.REMOVE_MUTATTION_PATIENT;
export const SET_PATIENT_VISIT_DETAIL = variable.PATIENT.SET_PATIENT_VISIT_DETAIL;
export const REMOVE_PATIENT_VISIT_DETAIL = variable.PATIENT.REMOVE_PATIENT_VISIT_DETAIL;
export const SELECTED_DOCTOR_ARRAY_MUTATION = variable.DOCTOR.SELECTED_DOCTOR_ARRAY_MUTATION;
export const MUTATE_REMOVE_USER_ID = variable.DOCTOR.MUTATE_REMOVE_USER_ID;
export const REMOVE_SELECTED_DOCTOR_ARRAY_MUTATION = variable.DOCTOR.REMOVE_SELECTED_DOCTOR_ARRAY_MUTATION;
export const MUTATE_GET_PATIENT_VISIT_INIT_DATA = variable.PATIENT.MUTATE_GET_PATIENT_VISIT_INIT_DATA;
export const MUTATE_EDIT_ADDITIONAL_DATA_CHANGE = variable.PATIENT.MUTATE_EDIT_ADDITIONAL_DATA_CHANGE;
export const MUTATE_SET_INITIAL_BIOPSY_DATA = variable.PATIENT.MUTATE_SET_INITIAL_BIOPSY_DATA;
export const MUTATE_EDIT_PATIENT_VISIT_FLAGGED_DATA = variable.PATIENT.MUTATE_EDIT_PATIENT_VISIT_FLAGGED_DATA;
export const MUTATE_SET_INITIAL_BI_RADS_DATA = variable.PATIENT.MUTATE_SET_INITIAL_BI_RADS_DATA;
export const MUTATE_REQUEST_BIOPSY_DATA_CHANGE = variable.PATIENT.MUTATE_REQUEST_BIOPSY_DATA_CHANGE;
export const MUTATE_SET_CURRENT_PAGE = variable.PATIENT.MUTATE_SET_CURRENT_PAGE;
export const MUTATE_EDIT_PATIENT_BI_RADS_DATA = variable.PATIENT.MUTATE_EDIT_PATIENT_BI_RADS_DATA;
export const MUTATE_EDIT_DATE_OF_BIRTH = variable.PATIENT.MUTATE_EDIT_DATE_OF_BIRTH;
export const REMOVE_USER_ID = variable.PATIENT.REMOVE_USER_ID;
export const MUTATE_ADD_DOCUMENT = variable.PATIENT.MUTATE_ADD_DOCUMENT;
export const MUTATE_DELETE_DOCUMENT = variable.PATIENT.MUTATE_REMOVE_DOCUMENT;
export const MUTATE_DOCUMENT_ERROR = variable.PATIENT.MUTATE_DOCUMENT_ERROR;

const state = {
  selectedPatientVisits: null,
  selectedPatient: null,
  patientsArray: [],
  doctorArray: [],
  tableData: {},
  visitDetailFilter: 0,
  currentSelectedPage: 0,
  currentFilter: {
    ShowOnlyFaggedData: false,
    PageNumber: 0,
    Status: null,
    FirstDate: null,
    LastDate: null,
    UsersIds: [],
    ClinicAccountId: null,
    sortBy: null,
    direction: null,
    Age: null,
    BiRads: null,
    PalpableMassInTheBreast: null,
    FamilyHistory: null,
    PainInTheBreast: null,
    BiopsyResult: null,
    TumorSide: null,
    TumorSize: null,
    TumorLocation: null,
  },
  patientEditedData: {
    error: false,
    data: null,
    message: null,
  },
  patientInitialData: null,
  patientVisitDetail: {
    PatientVisitId: null,
    PalpableMassInTheBreast: null,
    PainInTheBreast: null,
    AgeOfPatient: null,
    MenstrualCycle: null,
    BiRadsLeft: null,
    BiRadsRight: null,
    Photos: null,
    Documents: null,
    FlaggedData: null,
    BiopsyResult: null,
    TumorSite: null,
    TumorSize: null,
    TumorLocation: null,
    Comment: null,
    IsAnnotated: false
  },
  documentError: null,
  selectedDoctorsArray: [],
  patientUpdatedDataError: null,
};

const getters = {
  getNumberPagination(state) {
    return state.currentSelectedPage;
  },
  getPatientInitialData(state) {
    return state.patientInitialData;
  },
  getFilterData(state) {
    return state.visitDetailFilter;
  },
  getSelectedPatient(state) {
    return state.selectedPatient;
  },
  getPatientsArray(state) {
    return state.patientsArray;
  },
  getDoctorsArray(state) {
    return state.doctorArray;
  },
  getPatientTableStat(state) {
    return state.tableData;
  },
  getCurrentFilter(state) {
    return state.currentFilter;
  },
  getPatientVisitDetail(state) {
    return state.patientVisitDetail;
  },
  getDocumentError(state) {
    return state.documentError;
  },
  getSelectedDoctorsArray(state) {
    return state.selectedDoctorsArray;
  },
};

const actions = {
    [REMOVE_USER_ID]({commit}) {
      commit(MUTATE_REMOVE_USER_ID);
    },
    [SET_CURRENT_PAGE]({commit}, value) {
      commit(MUTATE_SET_CURRENT_PAGE, value);
    },
    [VISIT_DETAIL_FILTER_ACTION]({commit}, value) {
      commit(MUT_VISIT_DETAIL_FILTER_ACTION, value);
    },
    async [GET_PATIENT_VISIT_INIT_DATA]({commit}, obj) {
      try {
        const {data} = await apiService.query(`${process.env.VUE_APP_API_PATH}/${process.env.VUE_APP_PATIENT}/${GET_PATIENT_VISIT_INIT_DATA}`, obj)
        if (!data?.HasError) {
          commit(MUTATE_GET_PATIENT_VISIT_INIT_DATA, data.Data);
        }
      } catch ({response}) {
        commit('SetErrorMutation', {message: response?.data?.Message});
      }
    },
    async [EDIT_ADDITIONAL_DATA_CHANGE]({commit}, obj) {
      try {
        await apiService.post(`${process.env.VUE_APP_API_PATH}/${process.env.VUE_APP_PATIENT}/${EDIT_ADDITIONAL_DATA_CHANGE}`, obj)
        commit(MUTATE_EDIT_ADDITIONAL_DATA_CHANGE, obj);
      } catch ({response}) {
        commit('SetErrorMutation', {message: response?.data?.Message});
      }
    }
    ,
    async [EDIT_PATIENT_VISIT_FLAGGED_DATA_ACTION]({commit}, obj) {
      try {
        await apiService.post(`${process.env.VUE_APP_API_PATH}/${process.env.VUE_APP_PATIENT}/${EDIT_PATIENT_VISIT_FLAGGED_DATA}`, obj)
        commit(MUTATE_EDIT_PATIENT_VISIT_FLAGGED_DATA, obj.FlaggedData);
      } catch ({response}) {
        commit('SetErrorMutation', {message: response?.data?.Message});
      }
    }
    ,
    async [EDIT_PATIENT_BI_RADS_DATA]({commit}, obj) {
      try {
        await apiService.post(`${process.env.VUE_APP_API_PATH}/${process.env.VUE_APP_PATIENT}/${EDIT_PATIENT_BI_RADS_DATA}`, obj)
        commit(MUTATE_EDIT_PATIENT_BI_RADS_DATA, obj);
      } catch ({response}) {
        commit('SetErrorMutation', {message: response?.data?.Message});
      }
    }
    ,
    async setDateOfBirth({commit}, obj) {
      try {
        await apiService.post(`${process.env.VUE_APP_API_PATH}/${process.env.VUE_APP_PATIENT}/${SET_DATE_OF_BIRTH}`, obj)
        commit(MUTATE_EDIT_DATE_OF_BIRTH, obj.dateOfBirth);
      } catch ({response}) {
        const message = response?.data?.Message;
        if (message) {
          throw message;
        } else {
          throw "Something went wrong. Please try again a bit later.";
        }
      }
    }
    ,
    async addDocument({commit}, obj) {
      try {
        const {data} = await apiService.post(`${process.env.VUE_APP_API_PATH}/${process.env.VUE_APP_PATIENT}/${ADD_DOCUMENT}`, obj);
        if (!data?.HasError) {
          commit(MUTATE_ADD_DOCUMENT, data.Data);
          commit(MUTATE_DOCUMENT_ERROR, null);
        }
      } catch ({response}) {
        commit(MUTATE_DOCUMENT_ERROR, response?.data?.Message);
      }
    }
    ,
    async deleteDocument({commit}, obj) {
      try {
        const {data} = await apiService.queryDelete(`${process.env.VUE_APP_API_PATH}/${process.env.VUE_APP_PATIENT}/${DELETE_DOCUMENT}`,
          {documentId: obj.documentId});
        if (!data?.HasError) {
          commit(MUTATE_DELETE_DOCUMENT, obj.documentIndex);
        }
      } catch ({response}) {
        commit('SetErrorMutation', {message: response?.data?.Message});
      }
    }
    ,
    [SET_INITIAL_BIOPSY_DATA]({commit}) {
      commit(MUTATE_SET_INITIAL_BIOPSY_DATA);
    }
    ,
    [SET_INITIAL_BI_RADS_DATA]({commit}) {
      commit(MUTATE_SET_INITIAL_BI_RADS_DATA);
    }
    ,
    async [REQUEST_BIOPSY_DATA_CHANGE]({commit}, obj) {
      try {
        const {data} = await apiService.post(`${process.env.VUE_APP_API_PATH}/${process.env.VUE_APP_PATIENT}/${REQUEST_BIOPSY_DATA_CHANGE}`, obj)
        if (!data?.HasError) {
          commit(MUTATE_REQUEST_BIOPSY_DATA_CHANGE, obj);
        }
      } catch ({response}) {
        commit('SetErrorMutation', {message: response?.data?.Message});
      }

    }
    ,
    async [DELETE_PATIENT]({commit}, object) {
      try {
        apiService.queryDelete(`${process.env.VUE_APP_API_PATH}/${process.env.VUE_APP_PATIENT}/${DELETE_PATIENT}`, object)
      } catch ({response}) {
        commit('SetErrorMutation', {message: response?.data?.Message});
      }
    },
    async [EDIT_PATIENT_ID](context, object) {
      try {
        const {data} = await apiService.post(`${process.env.VUE_APP_API_PATH}/${process.env.VUE_APP_PATIENT}/${EDIT_PATIENT_ID}`, object)
        if (!data.HasError) {
          context.commit(MUTATE_EDIT_PATIENT_ID, data);
        }
      } catch ({response}) {
        throw response?.data?.Message ?? 'Something went wrong. Please try again a bit later.'
      }
    }
    ,
    async getAllPatients({commit}, filterModel) {
      try {
        filterModel.direction = filterModel.direction || 1;
        filterModel.sortBy = filterModel.sortBy || 1;
        filterModel.PageNumber = (filterModel.PageNumber && filterModel.PageNumber > 0) ? filterModel.PageNumber : 0;
        filterModel.ShowOnlyFaggedData = filterModel.ShowOnlyFaggedData || false;

        const {data} = await apiService.patch(`${process.env.VUE_APP_API_PATH}/${process.env.VUE_APP_PATIENT}/${GET_ALL_PATIENTS}`, filterModel)
        if (!data?.HasError) {
          commit(SET_PATIENT_ARRAY, data.Data);
          commit(SET_TABLE_STAT, data.Data);
          commit(SET_FILTER_STAT, filterModel);
        }
      } catch ({response}) {
        commit(MUTATE_DOCUMENT_ERROR, response?.data?.Message);
        commit(REMOVE_PATIENTS_ARRAY);
        commit(REMOVE_TABLE_STAT);
        commit(REMOVE_FILTER_STAT);
        commit('SetErrorMutation', {message: response?.data?.Message});
      }
    }
    ,
    async [GET_VISIT_DETAILS]({commit}, credentials) {
      try {
        const {data} = await apiService.query(`${process.env.VUE_APP_API_PATH}/${process.env.VUE_APP_PATIENT}/${GET_VISIT_DETAILS}`,
          {patientVisitId: credentials.patientVisitId})
        if (!data.HasError) {
          const userId = {index: credentials.index};
          console.log('credentials', credentials)
          commit(SET_PATIENT_VISIT_DETAIL, {...userId, ...data.Data});
        }
      } catch ({response}) {
        commit(REMOVE_PATIENT_VISIT_DETAIL);
        commit('SetErrorMutation', {message: response?.data?.Message});
      }
    }
    ,
    async getAllUsers({commit}) {
      try {
        const {data} = await apiService.get(`${process.env.VUE_APP_API_PATH}/${process.env.VUE_APP_USER_SMALL_LETTER}/${GET_ALL_USERS}`)
        if (!data.HasError) {
          commit(SET_DOCTOR_ARRAY, data.Data);
        }
      } catch ({response}) {
        commit(REMOVE_PATIENTS_ARRAY);
        commit('SetErrorMutation', {message: response?.data?.Message});
      }
    }
    ,
    [PURGE_PATIENT_ARRAY](context, data) {
      context.commit(REMOVE_PATIENTS_ARRAY);
      context.commit(REMOVE_DOCTOR_ARRAY);
      context.commit(REMOVE_TABLE_STAT);
      context.commit(REMOVE_FILTER_STAT, data);
      context.commit(REMOVE_PATIENT_VISIT_DETAIL);
    }
    ,
    [SELECTED_DOCTOR_ARRAY]({commit}, value) {
      const arr = Array.isArray(value) && value.length > 0 ? value : [];
      commit(SELECTED_DOCTOR_ARRAY_MUTATION, arr);
    }
    ,
    [REMOVE_SELECTED_DOCTOR_ARRAY]({commit}) {
      commit(REMOVE_SELECTED_DOCTOR_ARRAY_MUTATION);
    }
    ,
    [REMOVE_CURRENT_PATIENT]({commit}) {
      commit(REMOVE_MUTATTION_PATIENT);
    }
    ,
    [REMOVE_VISIT_DETAILS]({commit}) {
      commit(REMOVE_PATIENT_VISIT_DETAIL);
    }
    ,
  }
;

const mutations = {
  [MUTATE_REMOVE_USER_ID](state) {
    state.currentFilter.UsersIds = [];
  },
  [MUTATE_SET_CURRENT_PAGE](state, data) {
    state.currentSelectedPage = data;
    state.currentFilter.PageNumber = data;
  },
  [MUTATE_EDIT_ADDITIONAL_DATA_CHANGE](state, data) {
    Vue.set(state.patientVisitDetail, 'PalpableMassInTheBreast', data.palpableMassInTheBreast);
    Vue.set(state.patientVisitDetail, 'PainInTheBreast', data.painInTheBreast);
    Vue.set(state.patientVisitDetail, 'MenstrualCycle', data.menstrualCycle);
  },
  [MUTATE_EDIT_PATIENT_BI_RADS_DATA](state, data) {
    Vue.set(state.patientVisitDetail, 'BiRadsLeft', data.biRadsLeft);
    Vue.set(state.patientVisitDetail, 'BiRadsRight', data.biRadsRight);
  },
  [MUTATE_REQUEST_BIOPSY_DATA_CHANGE](state, data) {
    Vue.set(state.patientVisitDetail, 'TumorSize', data.tumorSize);
    Vue.set(state.patientVisitDetail, 'BiopsyResult', data.biopsyResult);
    Vue.set(state.patientVisitDetail, 'TumorSite', data.tumorSite);
    Vue.set(state.patientVisitDetail, 'TumorLocation', data.tumorLocation);
  },
  [MUTATE_GET_PATIENT_VISIT_INIT_DATA](state, data) {
    state.patientInitialData = data;
    // state.selectedPatient = data.PatientInfo;
  },
  [MUT_VISIT_DETAIL_FILTER_ACTION](state, value) {
    state.visitDetailFilter = value;
  },
  [MUTATE_ADD_DOCUMENT](state, value) {
    if (!Array.isArray(state.patientVisitDetail.Documents)) {
      state.patientVisitDetail.Documents = [];
    }
    state.patientVisitDetail.Documents.push(value);
  },
  [MUTATE_DOCUMENT_ERROR](state, value) {
    state.documentError = value;
  },

  [MUTATE_DELETE_DOCUMENT](state, index) {
    if (Array.isArray(state.patientVisitDetail.Documents)) {
      state.patientVisitDetail.Documents.splice(index, 1);
    }
  },
  [MUTATE_EDIT_PATIENT_VISIT_FLAGGED_DATA](state, object) {
    state.patientVisitDetail.FlaggedData = object;
  },
  [MUTATE_EDIT_PATIENT_ID](state, data) {
    state.patientUpdatedDataError = null;
    state.patientInitialData.PatientInfo.ClinicAccountId = data.Data.ClinicAccountId;
  },
  [MUTATE_EDIT_DATE_OF_BIRTH](state, data) {
    state.patientInitialData.PatientInfo.DateOfBirth = data;
    state.patientUpdatedDataError = null;
  },
  // SetPatientUpdatedDataError(state, error) {
  //   state.patientUpdatedDataError = error;
  // },
  [REMOVE_SELECTED_DOCTOR_ARRAY_MUTATION](state) {
    state.selectedDoctorsArray = [];
  },
  [SELECTED_DOCTOR_ARRAY_MUTATION](state, data) {
    state.selectedDoctorsArray = data;
  },
  [REMOVE_PATIENT_VISIT_DETAIL](state) {
    state.patientVisitDetail = {
      PatientVisitId: null,
      PalpableMassInTheBreast: null,
      PainInTheBreast: null,
      AgeOfPatient: null,
      MenstrualCycle: null,
      BiRadsLeft: null,
      BiRadsRight: null,
      Photos: null,
      Documents: null,
      FlaggedData: null,
      BiopsyResult: null,
      TumorSite: null,
      TumorSize: null,
      TumorLocation: null,
    };
  },
  [SET_PATIENT_VISIT_DETAIL](state, data) {
    state.patientVisitDetail = data;
  },
  // [SET_CURRENT_PATIENT](state, data) {
  //   state.selectedPatient = data;
  // },
  [REMOVE_MUTATTION_PATIENT](state) {
    state.selectedPatient = null;
  },
  [SET_DOCTOR_ARRAY](state, data) {
    state.doctorArray = data;
  },
  [SET_PATIENT_ARRAY](state, data) {
    state.patientsArray = data.Patients;
  },
  [SET_TABLE_STAT](state, data) {
    state.tableData = data.PatinetsCount !== 'undefined' ? new PatientTableStatsClass(null, data.PatinetsCount) : 0;
  },
  [REMOVE_TABLE_STAT](state) {
    state.tableData = {};
  },
  [SELECT_PATIENT_PROFILE](state, data) {
    state.selectedPatientVisits = data;
  },
  [REMOVE_DOCTOR_ARRAY](state) {
    state.doctorArray = [];
  },
  [REMOVE_PATIENTS_ARRAY](state) {
    state.patientsArray = [];
  },
  [REMOVE_SELECTED_PATIENT](state) {
    state.selectedPatientVisits = null;
  },
  [REMOVE_FILTER_STAT](state, data) {
    const d = data === true ? state.currentFilter.Status : -1;
    const e = data === true ? state.currentFilter.PageNumber : 0;
    state.currentFilter = new PatientClassCallObject(
      null,
      d,
      null,
      null,
      e,
      state.currentFilter.UsersIds,
      false,
    );
  },
  [SET_FILTER_STAT](state, data) {
    state.currentFilter = data;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
