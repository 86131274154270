class TableStats {
  constructor(
    currentPage,
    // paginationTotal,
    patientsCount,
  ) {
    this.currentPage = currentPage || null;
    // this.paginationTotal = paginationTotal || null;
    this.patientsCount = patientsCount || 0;
  }
}

export default TableStats;
