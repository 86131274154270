<template>
  <ValidationProvider :rules="inputRule" :name="nameInput">
    <b-form-group
      slot-scope="{ valid, errors }"
    >
      <b-input-group
        :size="inputSize"
        :prepend="preprepandText !== null ? preprepandText : null"
        :class="classArray"
      >
        <b-input-group-prepend v-if="prepandProperty" is-text>
          <slot name="prepandInputSlot" />
        </b-input-group-prepend>
        <b-form-input
          ref="specialValue"
          autocomplete="off"
          @keyup="onInputChange"
          :type="inputType"
          v-model="inputValueVmodel"
          :placeholder="placeholderText"
          class="th-input-wrapper-child"
          :state="stateProps ? false : (valid ? true : null)"
          :readonly="readOnlyProps"
        />
        <b-input-group-append v-if="appendInputSlot" is-text>
          <slot name="appendInputSlot" />
        </b-input-group-append>
        <b-form-invalid-feedback v-if="false">
          {{ errors[0] }}
        </b-form-invalid-feedback>
      </b-input-group>
    </b-form-group>
  </ValidationProvider>
</template>

<script>
import { ValidationProvider } from 'vee-validate';

export default {
  name: 'input-custom',
  components: {
    ValidationProvider,
  },
  props: {
    nameInput: {
      type: String,
      default: 'custom-input',
    },
    stateProps: {
      type: Boolean,
      default: false,
    },
    inputRule: {
      type: String,
      default: 'required',
    },
    inputSize: {
      type: String,
      default: 'sm',
    },
    prepandProperty: {
      type: Boolean,
      default: false,
    },
    readOnlyProps: {
      type: Boolean,
      default: false,
    },
    appendInputSlot: {
      type: Boolean,
      default: false,
    },
    preprepandText: {
      type: String,
      default: null,
    },
    classArray: {
      type: Array,
      default: () => ['mb-2'],
    },
    placeholderText: {
      type: String,
      default: null,
    },
    inputType: {
      type: String,
      default: 'text',
    },
    inputValue: {
      type: [Number, String, Date],
      default: null,
    },
    restrictSpecialSymbols: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    let value = null;
    if (this.inputType === 'date') {
      if(this.inputValue) {
        const date = new Date(this.inputValue);
        if (date.getTime() !== new Date(0).getTime()) {
          const currentDate = `0${date.getDate()}`.slice(-2);
          const currentMonth = `0${date.getMonth() + 1}`.slice(-2);
          const currentYear = `000${date.getFullYear()}`.slice(-4);
          value = `${currentYear}-${currentMonth}-${currentDate}`;
        }
        return {
          inputValueVmodel: value,
        };
      }
    }
    return {
      inputValueVmodel: this.inputValue,
    };
  },
  methods: {
    onInputChange(value) {
      if(this.restrictSpecialSymbols) {
        value.target.value
          = value.target.value.replace(/[`~!@#$%^&*()_\-+=<>?:"{}|,.\/;'\\[\]·~！@#￥%……&*（）——\-+={}|《》？：“”【】、；‘’，。、]/g, '');
        this.$emit('keyUpInputEvent', value.target.value);
      } else {
        this.$emit('keyUpInputEvent', this.inputValueVmodel);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
  .form-group {
    margin-bottom: 0;
  }

  .th-input-wrapper-child {
    background-color: $transparent;
    @include space(padding, top, 13px !important);
    @include space(padding, right, 28px !important);
    @include space(padding, bottom, 15px !important);
    @include space(padding, left, 22px !important);
    @include border-radius(31px !important);
    @include border(1px, solid, rgba(30, 45, 62, 0.1));
    @include font-source($openSans, 14px !important, false, normal, 1.21 !important, normal);
    height: 45px !important;

    &:focus {
      box-shadow: $none !important;
      border-color: $transparent;
    }
  }

  .form-control.is-valid {
    border-color: $transparent;
    background-image: none;
    &:focus {
      border-color: $transparent;
    }
  }

  input[type="date"]::-webkit-inner-spin-button,
  input[type="date"]::-webkit-calendar-picker-indicator {
    display: none;
    -webkit-appearance: none;
  }
</style>
