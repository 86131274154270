<template>
  <transition name="modal">
    <div class="modal-mask" v-if="error">
      <div class="modal-container">
        <div class="modal-header">
          <b-icon @click="closeModal()" icon="x"/>
        </div>
        <div>
          <div class="modal-header-title">
            <h6>
              {{ error.title != null ? error.title : "" }}
            </h6>
          </div>
          <div class="modal-footer">
            {{ error.message != null ? error.message : "" }}

            <!--                      <sbmt-btn-->
            <!--                        :buttonText="$t('YES', currentSelectedLanguage)"-->
            <!--                        @buttonEventEmit="submitModal('delete')"-->
            <!--                      />-->
            <!--                      <sbmt-btn-->
            <!--                        :buttonText="$t('NO', currentSelectedLanguage)"-->
            <!--                        @buttonEventEmit="closeModal()"-->
            <!--                      />-->
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>

import {mapActions, mapGetters} from "vuex";

export default {
  name: "errorPopup",
  computed: {
    ...mapGetters({
      error: 'getError'
    })
  },
  methods: {
    async closeModal() {
      await this.$store.dispatch('SetError', null);
    },
    updateOnlineStatus(e) {
      const {
        type
      } = e;
      this.onLine = type === 'online';
      if (type === 'online') {
        this.setError(null);
      } else {
        this.setError({
          title: "No internet",
          message: "Check the network cables, modem, and router or reconnect to Wi-Fi."
        });
      }
    },
    ...mapActions({
      setError: 'SetError',
    }),
  },
  mounted() {
    if (this.onLine) {
      this.setError(null);
    } else {
      this.setError({
        title: "No internet",
        message: "Check the network cables, modem, and router or reconnect to Wi-Fi."
      });
    }
    window.addEventListener('online', this.updateOnlineStatus);
    window.addEventListener('offline', this.updateOnlineStatus);
  },
  beforeDestroy() {
    window.removeEventListener('online', this.updateOnlineStatus);
    window.removeEventListener('offline', this.updateOnlineStatus);
  },
  data() {
    return {
      onLine: navigator.onLine
    }
  }
}
</script>

<style lang="scss" scoped>
.modal {
  &-mask {
    position: fixed;
    z-index: 9999;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(30, 45, 62, 0.2);
    transition: opacity .3s ease;
  }

  &-container {
    width: 348px;
    margin: 100px auto 0;
    padding: 26px 27px 30px 27px;
    background-color: #fff;
    border-radius: 2px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, .33);
    transition: all .3s ease;
    font-family: Helvetica, Arial, sans-serif;
  }

  &-body {
    margin: 20px 0;
  }

  &-header {
    position: relative;
    padding: 0;
    border: none;
    height: 13px;

    & > svg {
      color: $zeus-blue;
      position: absolute;
      right: -17px;
      top: -17px;
      font-size: 40px;
      cursor: pointer;
    }
  }

  &-header-title {
    & > h6 {
      @include font-source($openSans, 18px, $zeus-blue, 600, normal, normal);
      text-align: center;
      color: rgb(205, 111, 111);
    }
  }

  &-footer {
    padding: 0;
    border: none;
    justify-content: space-between;
  }
}

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>
