import { extend } from 'vee-validate';
import * as rules from 'vee-validate/dist/rules';
import localVariable from '../helpers/variables/localVariables';

// install vee validate all rules
Object.keys(rules).forEach((rule) => {
  extend(rule, rules[rule]);
});

// Custom rule at least 8 character, One uppercase, One lowercase, One Special character, One digit
extend(localVariable.CUSTOM_RULES.STRONG_PASSWORD, {
  message:
    '{_field_} must be at least 8 characters, contain One Uppercase, One lowercase, One Special character i.e (! @ # $ % ^ & *), One number.',
  validate: (value) => {
    const strongPassword = new RegExp(
      '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})',
    );

    return strongPassword.test(value);
  },
});
