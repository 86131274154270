import Vue from 'vue';
import axios from 'axios';
import VueAxios from 'vue-axios';
import { getToken } from './jwt';

const token = getToken();

const accessToken = token !== null ? token.token : ' ';

const ApiService = {
  /**
   * Initialize axios connetion
   */
  init() {
    Vue.use(VueAxios, axios);
    Vue.axios.defaults.baseURL = process.env.VUE_APP_BACKEND_ADDRESS;
  },

  /**
   * Set the default HTTP request headers
   */
  setHeader() {
    Vue.axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  },

  /**
   * Send the GET HTTP request
   * @param resource
   * @param slug
   * @returns {*}
   */
  get(resource, slug = '') {
    return Vue.axios.get(`${resource}/${slug}`).catch((error) => {
      throw new Error(error);
    });
  },

  query(resource, params) {
    return Vue.axios.get(resource, { params }).catch((error) => {
      throw new Error(error);
    });
  },

  queryDelete(resource, params) {
    return Vue.axios.delete(resource, { params }).catch((error) => {
      throw new Error(error);
    });
  },

  /**
   * Set the PATCH HTTP request
   * @param resource
   * @param params
   * @returns {*}
   */
  patch(resource, params) {
    return Vue.axios.patch(`${resource}`, params).catch((error) => {
      throw new Error(error);
    });
  },

  /**
   * Set the POST HTTP request
   * @param resource
   * @param params
   * @returns {*}
   */
  post(resource, params) {
    return Vue.axios.post(`${resource}`, params);
  },
};

export default ApiService;
