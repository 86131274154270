<template>
  <div>
    <login-form/>
    <error-popup/>
  </div>
</template>

<script>
import loginForm from '../../components/moleculs/loginForm.vue';
import ErrorPopup from "../../components/moleculs/errorPopup";

export default {
  name: 'login-page',
  components: {
    ErrorPopup,
    loginForm,
  },
};
</script>
