<template>
  <h1 class="text-center" style="color: #ffffff">
    {{ $t("404", currentSelectedLanguage) }}
  </h1>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'PageNotFound',
  computed: {
    ...mapState([
      'currentSelectedLanguage',
    ]),
  },
};
</script>

<style scoped>

</style>
