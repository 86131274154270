import url from '../../core/helpers/variables/urlHelper';
import apiService from '../../core/services/api.services';
import variable from '../../core/helpers/variables/localVariables';
import {
  destroyToken,
  getIsPrivacyAcceptedFromLocalStorage,
  getProfile,
  saveIsPrivacyAccepted,
} from '../../core/services/jwt';

// action types
export const GET_USER_DEATILS = url.GET_USER_DETAILS;
export const GET_DOCTOR_PATIENTS_SCANS_FLAGGEDDATA = url.GET_DOCTOR_PATIENTS_SCANS_FLAGGEDDATA;
export const ACCEPT_PRIVACY = url.ACCEPT_PRIVACY;

// mutation type
export const SET_USER_PROFILE = variable.SET_PROFILE;
export const REMOVE_USER_PROFILE = variable.REMOVE_USER_PROFILE;
export const SET_USER_PROFILE_INIT_DATA = variable.SET_USER_PROFILE_INIT_DATA;
export const MUTATE_ACCEPT_PRIVACY = variable.MUTATE_ACCEPT_PRIVACY;

const state = {
  profile: {
    IsPrivacyAccepted: getIsPrivacyAcceptedFromLocalStorage(),
  },
  profileInitData: {},
};

const getters = {
  getProfile(state) {
    return state.profile;
  },
  getProfileInitData(state) {
    return state.profileInitData;
  },
  getIsPrivacyAccepted(state) {
    return state.profile.IsPrivacyAccepted;
  },
};

const actions = {
    async [GET_USER_DEATILS](context) {
      try {
        const res = await apiService.get(`${process.env.VUE_APP_API_PATH}/${process.env.VUE_APP_USER}/${GET_USER_DEATILS}`);
        if (!res.data.HasError) {
          context.commit(SET_USER_PROFILE, res.data.Data);
        }
      } catch (error) {
        context.commit(REMOVE_USER_PROFILE);
        context.commit('SetErrorMutation', {message: error?.response?.data?.Message});
      }
    },
    async [GET_DOCTOR_PATIENTS_SCANS_FLAGGEDDATA](context) {
      try {
        const res = await apiService.get(`${process.env.VUE_APP_API_PATH}/${process.env.VUE_APP_USER}/${GET_DOCTOR_PATIENTS_SCANS_FLAGGEDDATA}`)
        if (!res?.data?.HasError) {
          context.commit(SET_USER_PROFILE_INIT_DATA, res.data.Data);
        }
      } catch ({response}) {
        context.commit(REMOVE_USER_PROFILE);
        context.commit('SetErrorMutation', {message: response?.data?.Message});
      }
    },
    async AcceptPrivacy({commit}) {
      try {
        const res = await apiService.patch(`${process.env.VUE_APP_API_PATH}/${process.env.VUE_APP_USER}/${ACCEPT_PRIVACY}`,
          {PrivacyAcceptedBy: 0})
        if (!res.data.HasError) {
          commit(MUTATE_ACCEPT_PRIVACY, true);
          saveIsPrivacyAccepted(true);
        }
      } catch ({response}) {
        commit('SetErrorMutation', {message: response?.data?.Message});
      }
    }
    ,
  }
;

const mutations = {
  [SET_USER_PROFILE](state, data) {
    const userStorage = getProfile();
    state.profile = data;
    state.profile.userRole = userStorage
    && userStorage.role !== undefined
      ? userStorage.role : null;
    saveIsPrivacyAccepted(data.IsPrivacyAccepted);
  },
  [SET_USER_PROFILE_INIT_DATA](state, data) {
    state.profileInitData = data;
  },
  [REMOVE_USER_PROFILE](state) {
    state.profile = {};
    state.profileInitData = {};
    destroyToken();
    window.location.reload();
  },
  [MUTATE_ACCEPT_PRIVACY](state, value) {
    state.profile.IsPrivacyAccepted = value;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
