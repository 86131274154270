class PatientCall {
  constructor(
    ClinicAccountId,
    Status,
    FirstDate,
    LastDate,
    PageNumber,
    UsersIds,
    ShowOnlyFaggedData,
    sortBy,
    direction,
    age,
    biRads,
  ) {
    this.ClinicAccountId = ClinicAccountId || null;
    this.Status = Status;
    this.FirstDate = FirstDate || null;
    this.LastDate = LastDate || null;
    this.PageNumber = PageNumber || 0;
    this.UsersIds = UsersIds.length > 0 ? UsersIds : [];
    this.ShowOnlyFaggedData = ShowOnlyFaggedData || false;
    this.sortBy = sortBy || 1;
    this.direction = direction || 1;
    this.Age = age;
    this.BiRads = biRads;
  }
}

export default PatientCall;
