import {
  getToken, saveProfile, saveToken, destroyToken,
} from '../../core/services/jwt';
import apiService from '../../core/services/api.services';
import statusCodesObject from '../../core/helpers/variables/statusCodes';
import url from '../../core/helpers/variables/urlHelper';
import {intercepterResponse, interceptRequest} from '../../core/services/interceptor';
import {
  PURGE_PATIENT_ARRAY,
  REMOVE_SELECTED_DOCTOR_ARRAY,
  SET_CURRENT_PAGE,
  VISIT_DETAIL_FILTER_ACTION,
  REMOVE_USER_ID,
} from './patients.module';
import variable from '../../core/helpers/variables/localVariables';

// action types
export const VERIFY_AUTH = url.VERIFY_AUTH;
export const LOGIN = url.LOGIN;
export const LOGOUT = url.LOGOUT;
export const FORGET_PASSWORD = url.FORGET_PASSWORD;

// Mutation types
export const SET_ERROR = url.SET_ERROR;
export const SET_AUTH = url.SET_USER;
export const PURGE_AUTH = url.PURGE_AUTH;
export const PURGE_ERROR = variable.PURGE_ERROR;
export const PURGE_MUTATE_ERROR = variable.PURGE_MUTATE_ERROR;

const state = {
  errors: null,
  user: {},
  isAuthenticated: !!getToken(),
  statusCodes: statusCodesObject,
};

const getters = {
  currentUser(state) {
    return state.user;
  },
  isAuthenticated(state) {
    return state.isAuthenticated;
  },
};

const actions = {
  async [FORGET_PASSWORD](context, credentials) {
    try {
      await apiService.query(`${process.env.VUE_APP_API_PATH}/${process.env.VUE_APP_USER}/${FORGET_PASSWORD}`, credentials);
    } catch ({response}) {
      await context.dispatch(
        variable.LOADER.LOADER_SET,
        variable.LOADER.LOADER_REMOVE_VALUE,
        {root: true},
      );
      context.commit('SetErrorMutation', {message: response?.data?.Message});
    }
  },
  async [LOGIN](context, credentials) {
    try {
      const {data} = await apiService.post(`${process.env.VUE_APP_API_PATH}/${process.env.VUE_APP_USER}/${LOGIN}`, credentials);
      if (!data.HasError) {
        context.commit(SET_AUTH, data.Data);
      }
    } catch ({response}) {
      await context.dispatch(
        variable.LOADER.LOADER_SET,
        variable.LOADER.LOADER_REMOVE_VALUE,
        {root: true},
      );
      context.commit('SetErrorMutation', {message: response?.data?.Message});
    }
  },
  [VERIFY_AUTH](context) {
    if (getToken()) {
      interceptRequest();
      intercepterResponse();
    } else {
      context.commit(PURGE_AUTH);
    }
  },
  [PURGE_ERROR](context) {
    context.commit(PURGE_MUTATE_ERROR);
  },
  async [LOGOUT](context) {
    try {
      const {data} = apiService.get(`${process.env.VUE_APP_API_PATH}/${process.env.VUE_APP_USER}/${LOGOUT}`)
      if (!data.HasError) {
        context.commit(PURGE_AUTH);
      } else {
        context.commit('SetErrorMutation', {message: data.Message});
      }
    } catch (error) {
      context.commit(PURGE_AUTH);
      context.commit('SetErrorMutation', {message: error?.response?.data?.Message});
    }
  }
};

const mutations = {
  [PURGE_MUTATE_ERROR](state) {
    state.errors = null;
  },
  [SET_ERROR](state, error) {
    state.errors = error.data;
  },
  [SET_AUTH](state, user) {
    state.isAuthenticated = true;
    state.user = user.Name;
    state.errors = {};

    const token = {
      token: user.Token,
      refresh_token: user.RefreshToken,
    };
    saveToken(token);

    const name = {
      name: user.Name,
      role: user.Roles.length > 0 ? user.Roles[0] : null,
      isPrivacyAccepted: user.IsPrivacyAccepted,
    };
    saveProfile(name);
  },
  [PURGE_AUTH](state) {
    state.isAuthenticated = false;
    state.user = {};
    state.errors = {};
    this.dispatch(VISIT_DETAIL_FILTER_ACTION, null);
    this.dispatch(SET_CURRENT_PAGE, null);
    this.dispatch(REMOVE_SELECTED_DOCTOR_ARRAY);
    this.dispatch(PURGE_PATIENT_ARRAY);
    this.dispatch(REMOVE_USER_ID);
    destroyToken();
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
