import axios from 'axios';
import {
  getToken, saveToken, destroyToken,
} from './jwt';
import apiService from './api.services';
import urlHelper from '../helpers/variables/urlHelper';

export function interceptRequest() {
  axios.interceptors.request.use((config) => {
    const conf = config;
    const getTokens = getToken();
    const accessToken = getTokens !== null ? getTokens.token : ' ';
    conf.headers.Authorization = `Bearer ${accessToken}`;
    return conf;
  });
}

export function intercepterResponse() {
  axios.interceptors.response.use((response) => response, (error) => {
    const originalRequest = error.config;
    if (error.response.status === 401 && originalRequest.url === `${process.env.VUE_APP_API_PATH}/${process.env.VUE_APP_USER}/${urlHelper.REFRESH_TOKEN}`) {
      this.$router.push({ name: 'Login' });
      return Promise.reject(error);
    }

    if (error.response.status === 401 && !originalRequest.retry) {
      originalRequest.retry = true;
      const refreshToken = getToken();

      const params = {
        RefreshToken: refreshToken.refresh_token,
      };
      return apiService.post(`${process.env.VUE_APP_API_PATH}/${process.env.VUE_APP_USER}/${urlHelper.REFRESH_TOKEN}`, params)
        .then((response) => {
          if (response.status === 201) {
            saveToken(response.data);
            axios.defaults.headers.common.Authorization = `Bearer ${refreshToken.token}`;
            return axios(originalRequest);
          }
        })
        .catch(() => {
          destroyToken();
        });
    }
    return Promise.reject(error);
  });
}

export default intercepterResponse;
