import Vue from 'vue';
import VueRouter from 'vue-router';
import Login from '../views/pages/Login.vue';
import pageNotFound from '../views/pages/PageNotFound.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/login',
    name: 'Login',
    alias: '/',
    component: Login,
    meta: {
      title: 'Login',
      requiresAuth: false,
      requiresAcceptPrivacy: false,
    },
  },
  {
    path: '/restoreAccess',
    name: 'restore-access',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/pages/restoreAccess.vue'),
    meta: {
      title: 'Restore Access',
      requiresAuth: false,
    },
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    redirect: '/home',
    meta: {
      requiresAuth: true,
      requiresAcceptPrivacy: true,
      title: 'Dashboard',
    },
    component: () => import('../views/layout/Dashboard.vue'),
    children: [
      {
        path: '/home',
        name: 'doctorHomepage',
        component: () => import('../views/pages/Dashboard/Home/Doctor/homePage.vue'),
        meta: {
          requiresAuth: true,
          requiresAcceptPrivacy: true,
          title: 'Home',
        },
      },
      {
        path: '/patients',
        name: 'doctorPatientpage',
        component: () => import('../views/pages/Dashboard/Pathients/Doctor/patientPage.vue'),
        meta: {
          requiresAuth: true,
          title: 'Patients',
          requiresAcceptPrivacy: true,
        },
      },
      {
        path: '/patients/detail/:patientId',
        name: 'doctorPatientpageDetail',
        component: () => import('../views/pages/Dashboard/Pathients/Doctor/patientPageDetail.vue'),
        meta: {
          title: 'Patient detail',
          requiresAuth: true,
          requiresAcceptPrivacy: true,
        },
        params: {
          allowOnlyWithPush: false,
        },
      },
      {
        path: '/photo/:patientId/:imageId',
        name: 'photoDetail',
        component: () => import('../views/pages/Dashboard/Pathients/Doctor/patientPagePhotoDetail.vue'),
        meta: {
          title: 'Patient photo detail',
          requiresAuth: true,
          requiresAcceptPrivacy: true,
        },
        params: {
          allowOnlyWithPush: true,
        },
      },
      {
        path: '/disclaimerPage',
        name: 'disclaimerPage',
        component: () => import('../views/pages/Dashboard/Home/Doctor/disclaimerPage.vue'),
        meta: {
          requiresAuth: true,
          requiresAcceptPrivacy: false,
          title: 'Disclaimer Page',
        },
      },
    ],
  },
  { path: '*', component: pageNotFound },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
