export const getToken = () => {
  if (window.localStorage.getItem('auth_token') !== null) {
    return JSON.parse(window.localStorage.getItem('auth_token'));
  }
  return null;
};

export const getProfile = () => {
  if (window.localStorage.getItem('profile') !== null) {
    return JSON.parse(window.localStorage.getItem('profile'));
  }
  return null;
};

export const getIsPrivacyAcceptedFromLocalStorage = () => {
  if (window.localStorage.getItem('profile') !== null) {
    const profile = JSON.parse(window.localStorage.getItem('profile'));
    return profile.isPrivacyAccepted;
  }
  return null;
};

export const saveToken = (token) => {
  window.localStorage.setItem('auth_token', JSON.stringify(token));
};

export const saveProfile = (token) => {
  window.localStorage.setItem('profile', JSON.stringify(token));
};

export const saveIsPrivacyAccepted = (isPrivacyAccepted) => {
  let profile;
  if (window.localStorage.getItem('profile') !== null) {
    profile = JSON.parse(window.localStorage.getItem('profile'));
  }
  profile.isPrivacyAccepted = isPrivacyAccepted;
  window.localStorage.setItem('profile', JSON.stringify(profile));
};

export const destroyToken = () => {
  // window.localStorage.removeItem('vuex');
  window.localStorage.removeItem('auth_token');
  window.localStorage.removeItem('profile');
};

export default {
  getToken, saveToken, destroyToken, saveProfile, getProfile,
};
