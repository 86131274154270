<template>
  <div style="background-color: #1e2d3e; height: 100%">
    <ValidationObserver ref="observerLogin">
      <b-form
        class="form-login"
        @submit.prevent="submitBtn()"
      >
        <b-form-group class="form-login-inp-wrp">
          <div class="form-login-inp-wrp--image">
            <img alt="login-logo" src="../../assets/login-logo.svg"/>
          </div>
          <p class="text-center form-login-inp-wrp--clinic">
            {{ $t('Clinic Dashboard', currentSelectedLanguage) }}
          </p>
        </b-form-group>
        <input-form
          ref="emailRef"
          class="form-login-inp-wrp"
          input-rule="required|email"
          name-input="Email"
          input-type="text"
          :classArray="['form-login-inp-wrp--nested']"
          :placeholderText="$t('email', currentSelectedLanguage)"
          @keyUpInputEvent="setEmailValue"
          :stateProps="error.email.condition"
        />
        <input-form
          ref="passRef"
          class="form-login-inp-wrp"
          :input-rule="`required|${this.rules.STRONG_PASSWORD}`"
          name-input="password"
          input-type="password"
          :classArray="['form-login-inp-wrp--nested']"
          :placeholderText="$t('password', currentSelectedLanguage)"
          @keyUpInputEvent="setPwdValue"
          :stateProps="error.password.condition"
        />

        <b-form-group class="form-login-btn-wrp">
          <sbmt-btn
            :buttonText="$t('login', currentSelectedLanguage)"
          />
        </b-form-group>

        <b-form-group class="form-login-link-wrp text-center">
          <b-link to="/restoreAccess">
            {{ $t('forgot password', currentSelectedLanguage) }}
          </b-link>
        </b-form-group>
        <b-form-invalid-feedback
          v-if="errors !== null"
          class="text-center"
          :state="!(Object.keys(errors).length !== 0 && errors.HasError)"
        >
          {{ errors.Message }}
        </b-form-invalid-feedback>
        <b-form-invalid-feedback
          class="text-center"
          :state="errorMessage"
        >
          {{ this.error.email.message + ` ` + this.error.password.message }}
        </b-form-invalid-feedback>
      </b-form>
    </ValidationObserver>
  </div>
</template>

<script>
import {mapGetters, mapState} from 'vuex';
import {ValidationObserver} from 'vee-validate';
import {LOGIN, PURGE_ERROR} from '../../store/modules/auth.module';
import sbmtBtn from '../atoms/button.vue';
import inputForm from '../atoms/input.vue';
import localVariable from '../../core/helpers/variables/localVariables';
import {SET_LOADER} from '../../store';

export default {
  name: 'loginForm',
  components: {
    ValidationObserver,
    sbmtBtn,
    inputForm,
  },
  data() {
    return {
      form: {
        email: null,
        password: null,
      },
      error: {
        email: {
          message: null,
          condition: false,
        },
        password: {
          message: null,
          condition: false,
        },
      },
      rules: localVariable.CUSTOM_RULES,
    };
  },
  computed: {
    ...mapState(
      {
        errors: (state) => state.auth.errors,
        currentSelectedLanguage: (state) => state.currentSelectedLanguage,
      },
    ),
    ...mapGetters({
      isAuthenticated: 'isAuthenticated'
    }),
    errorMessage() {
      return !(this.error.email.condition || this.error.password.condition);
    },
  },
  methods: {
    async submitBtn() {
      const isValid = await this.$refs.observerLogin.validate();
      this.error.email.message = this.$refs.observerLogin.errors.Email.length > 0
        ? this.$refs.observerLogin.errors.Email[0] : '';
      this.error.email.condition = this.$refs.observerLogin.errors.Email.length > 0;
      this.error.password.message = this.$refs.observerLogin.errors.password.length > 0
        ? this.$refs.observerLogin.errors.password[0] : '';
      this.error.password.condition = this.$refs.observerLogin.errors.password.length > 0;
      await this.$store.dispatch(PURGE_ERROR);
      if (isValid) {
        this.handleSubmit();
      }
    },
    handleSubmit() {
      this.$store.dispatch(SET_LOADER, localVariable.LOADER.LOADER_SET_VALUE);
      this.$store.dispatch(LOGIN, this.form).then(() => {
        this.$store.dispatch(SET_LOADER, localVariable.LOADER.LOADER_REMOVE_VALUE);
        if (this.isAuthenticated) {
          this.$router.push({name: 'Dashboard'}).catch(() => {
          });
        }
      });
    },
    setEmailValue(value) {
      this.form.email = value;
    },
    setPwdValue(value) {
      this.form.password = value;
    },
    setInitialData() {
      this.form.email = null;
      this.form.password = null;
      this.error.email.message = null;
      this.error.email.condition = null;
      this.error.password.message = null;
      this.error.password.condition = null;
    },
  },
  beforeDestroy() {
    this.setInitialData();
    this.$store.dispatch(PURGE_ERROR);
  },
};
</script>

<style lang="scss">
.form-login {
  max-width: 305px;
  padding-top: 218px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 0;

  &-inp-wrp {
    &:not(:first-child) {
      display: block;
      margin-bottom: 15px;
    }

    &:first-child {
      margin-bottom: 6px;
    }

    /* Nested inputs props class */
    .form-login-inp-wrp--nested {
      input {
        background-color: rgba(56, 68, 84, 0.6);
        color: $white;

        &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
          color: $white;
          opacity: 1; /* Firefox */
        }

        &:-ms-input-placeholder { /* Internet Explorer 10-11 */
          color: $white;
        }

        &::-ms-input-placeholder { /* Microsoft Edge */
          color: $white;
        }
      }
    }

    &--image {
      width: 297px;
      height: auto;
      @include space($type: margin, $direction: bottom, $amount: 6px);

      img {
        width: 100%;
        height: 100%;
      }
    }

    &--clinic {
      @include font-source($openSans, 12.6px, $white, normal, normal, normal);
      @include space($type: margin, $direction: bottom, $amount: 31px);
      text-transform: uppercase;
    }
  }

  &-btn-wrp {
    button {
      width: 100%;
      text-transform: uppercase;
    }
  }

  &-link-wrp {
    a {
      @include font-source($openSans, 11px, $white, normal, 1.55px, normal);

      &:hover, &:focus {
        color: $white;
        text-decoration: $none;
      }
    }
  }
}
</style>
