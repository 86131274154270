import Vue from 'vue';
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue';
import vSelect from 'vue-select';
import DatePicker from 'vue-time-date-range-picker/dist/vdprDatePicker';
import Maska from 'maska';
import VModal from 'vue-js-modal';
import vuescroll from 'vuescroll';
import 'vue-js-modal/dist/styles.css';
import imageZoom from 'vue-image-zoomer';
// Import vee validate components and rules begin
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import './core/services/veeValidateRule';
// Import vee validate components and rules end
import App from './App.vue';
import router from './router';
import store from './store';
import i18n from './i18n';
import apiService from './core/services/api.services';
import './directive/outsideClick';

import { VERIFY_AUTH } from './store/modules/auth.module';

import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import 'vue-select/dist/vue-select.css';

// API service init
apiService.init();
apiService.setHeader();

Vue.config.productionTip = false;

Vue.use(BootstrapVue);
Vue.use(IconsPlugin);

Vue.use(VModal, { componentName: 'modal' });

// Add vee validate components
Vue.component('ValidationObserver', ValidationObserver);
Vue.component('ValidationProvider', ValidationProvider);
// add v-select
Vue.component('v-select', vSelect);
// Add date range picker
Vue.component('datePicker', DatePicker);

Vue.component('image-zoom', imageZoom);

Vue.use(vuescroll);
Vue.use(Maska);

router.beforeEach((to, from, next) => {
  apiService.init();
  apiService.setHeader();
  // Ensure we checked auth before each page load.
  Promise.all([store.dispatch(VERIFY_AUTH)]).then((res) => {
    if (to.meta.requiresAuth) {
      // this route requires auth, check if logged in
      // if not, redirect to login page.
      if (!store.getters.isAuthenticated) {
        next({ name: 'Login' });
      } else if (to.meta.requiresAcceptPrivacy && !store.getters.getIsPrivacyAccepted) {
        next({ name: 'disclaimerPage' });
      } else {
        next();
      }
    } else if (store.getters.isAuthenticated && !to.meta.requiresAuth) {
      next({ name: 'Dashboard' });
    } else {
      next();
    }
  });
});

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount('#app');
