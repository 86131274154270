import url from '../../core/helpers/variables/urlHelper';
import apiService from '../../core/services/api.services';
import variable from '../../core/helpers/variables/localVariables';
import Vue from "vue";

// action types
export const GET_PHOTO_DETAILS = url.GET_PHOTO_DETAILS;
export const EDIT_PHOTO_DETAILS = url.EDIT_PHOTO_DETAILS;
export const DELETE_PHOTO = url.DELETE_PHOTO;

// mutation type
export const SET_PHOTO_DETAIL = variable.PHOTO.SET_PHOTO_DETAIL;
export const EDIT_PHOTO_DETAILS_MUTATION = variable.PHOTO.EDIT_PHOTO_DETAILS_MUTATION;
export const REMOVE_PHOTO_DETAIL = variable.PATIENT.REMOVE_PATIENTS_ARRAY;

const state = {
  photoDetailObject: {
    Base64Photo: "",
    Comment: null,
    FileName: "",
    HasFinalMarking: false,
    LabelLevel: null,
    Position: null,
  },
  photoAnnotations: {}
};

const getters = {
  getSelectedPhotoDetail(state) {
    console.log("state.photoDetailObject", state.photoDetailObject);
    return state.photoDetailObject;
  },
  getPhotoAnnotations(state) {
    console.log("getPhotoAnnotation", state.photoAnnotations);
    return state.photoAnnotations;
  }
};

const actions = {
  [DELETE_PHOTO](context, credentials) {
    return new Promise((resolve, reject) => {
      apiService.queryDelete(`${process.env.VUE_APP_API_PATH}/${process.env.VUE_APP_PHOTO}/${DELETE_PHOTO}`, credentials)
        .then(({data}) => {
          if (!data.HasError) {
            resolve(data.Data);
          }
        })
        .catch((error) => {
          context.commit('SetErrorMutation', {message: error?.response?.data?.Message});
        });
    });
  },
  async [GET_PHOTO_DETAILS](context, credentials) {
    try {
      const res = await apiService.query(`${process.env.VUE_APP_API_PATH}/${process.env.VUE_APP_PHOTO}/${GET_PHOTO_DETAILS}`, credentials)
      if (!res?.data?.HasError) {
        context.commit(SET_PHOTO_DETAIL, res.data.Data);
      }
    } catch (error) {
      context.commit(REMOVE_PHOTO_DETAIL);
      context.commit('SetErrorMutation', {message: error?.response?.data?.Message});
    }
  },
  async [EDIT_PHOTO_DETAILS](context, credentials) {
    try {
      await apiService.post(`${process.env.VUE_APP_API_PATH}/${process.env.VUE_APP_PHOTO}/${EDIT_PHOTO_DETAILS}`, credentials)
      context.commit(EDIT_PHOTO_DETAILS_MUTATION, credentials)
    } catch ({response}) {
      context.commit('SetErrorMutation', {message: response?.data?.Message});
    }
  },
  async addPhotoAnnotation(context, photoId) {
    try {
      const {data} = await apiService.post(`${process.env.VUE_APP_API_PATH}/${process.env.VUE_APP_PHOTO}/${url.ADD_PHOTO_ANNOTATION}`, photoId)
      if (!data?.HasError) {
        context.commit('AddPhotoAnnotation', data.Data)
      }
    } catch ({response}) {
      context.commit('SetErrorMutation', {message: response?.data?.Message});
    }
  },
  async getPhotoAnnotations(context, photoId) {
    try {
      const {data} = await apiService.query(`${process.env.VUE_APP_API_PATH}/${process.env.VUE_APP_PHOTO}/${url.GET_PHOTO_ANNOTATIONS}`, {photoId: photoId})
      if (!data?.HasError) {
        context.commit('GetPhotoAnnotations', data.Data)
      }
    } catch ({response}) {
      context.commit('SetErrorMutation', {message: response?.data?.Message});
    }
  },
  async deletePhotoAnnotation({commit,state}, index) {
    try {
      const {data} = await apiService.query(`${process.env.VUE_APP_API_PATH}/${process.env.VUE_APP_PHOTO}/${url.DELETE_PHOTO_ANNOTATION}`,
        {annotationId: state.photoAnnotations.Annotations[index].AnnotationId})
      if (!data?.HasError) {
        commit('DeletePhotoAnnotation', index)
      }
    } catch ({response}) {
      commit('SetErrorMutation', {message: response?.data?.Message});
    }
  },
  clearPhotoDetails(context) {
    context.commit(REMOVE_PHOTO_DETAIL);
  }
}

const mutations = {
  [SET_PHOTO_DETAIL](state, data) {
    state.photoDetailObject = data;
  },
  [REMOVE_PHOTO_DETAIL](state) {
    state.photoDetailObject = {};
  },
  [EDIT_PHOTO_DETAILS_MUTATION](state, data) {
    state.photoDetailObject.comment = data.comment;
    state.photoDetailObject.labelLevel = data.labelLevel;
  },
  AddPhotoAnnotation(state, data) {
    state.photoAnnotations.Annotations.push({
      AnnotationId: data.AnnotationId,
      Annotation: data.Annotation
    });
  },
  GetPhotoAnnotations(state, data) {
    Vue.set(state, "photoAnnotations", data);
  },
  DeletePhotoAnnotation(state, index) {
    state.photoAnnotations.Annotations.splice(index,1);
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
