const localVariable = {
  PURGE_ERROR: 'removeError',
  PURGE_MUTATE_ERROR: 'mutateError',
  CUSTOM_RULES: {
    STRONG_PASSWORD: 'strong_password',
  },
  SET_PROFILE: 'setProfile',
  REMOVE_USER_PROFILE: 'removeProfile',
  SET_USER_PROFILE_INIT_DATA: 'setProfileInitialData',
  MUTATE_ACCEPT_PRIVACY: 'acceptPrivacy',
  LOADER: {
    LOADER_SET: 'turnOnLoader',
    COMMIT_LOADER: 'callLoader',
    MUTATE_ELEMENT_COND_DURING_LOAD: 'callSetEleentCondition',
    LOADER_SET_VALUE: true,
    LOADER_REMOVE_VALUE: false,
    ELEMENT_COND_DURING_LOAD: 'setElemCondition',
  },
  PHOTO: {
    SET_PHOTO_DETAIL: 'setPhotoDetailsInformation',
    EDIT_PHOTO_DETAILS_MUTATION: 'editPhotoDetailsMutation',
    REMOVE_PHOTO_DETAIL: 'removePhotoDetailInformation',
  },
  PATIENT: {
    MUTATE_SET_CURRENT_PAGE: 'mutatesetAddDataCurrentData',
    REMOVE_USER_ID: 'removeUserId',
    SET_CURRENT_PAGE: 'setAddDataCurrentData',
    MUTATE_GET_PATIENT_VISIT_INIT_DATA: 'mutateGetInitialPatientData',
    MUTATE_EDIT_ADDITIONAL_DATA_CHANGE: 'mutateEditAdditionalData',
    MUTATE_EDIT_PATIENT_BI_RADS_DATA: 'mutateEditPatientBiRadsData',
    MUTATE_SET_ADDITIONAL_DATA: 'mutateSetAdditionalData',
    MUTATE_SET_INITIAL_BIOPSY_DATA: 'mutateSetInitialBiopsyData',
    MUTATE_SET_INITIAL_BI_RADS_DATA: 'mutateSetInitialBiRadsData',
    MUTATE_EDIT_PATIENT_VISIT_FLAGGED_DATA: 'mutateEditPatientVisitFlaggedData',
    MUTATE_REQUEST_BIOPSY_DATA_CHANGE: 'mutateBiopsyData',
    MUTATE_EDIT_DATE_OF_BIRTH: 'mutateEditDateOfBirth',
    VISIT_DETAIL_FILTER_ACTION: 'mutateFilter',
    SET_PATIENT: 'selectPatient',
    SET_PATIENTS_ARRAY: 'setPatientArray',
    REMOVE_PATIENTS_ARRAY: 'removePatientsArray',
    MUTATE_BI_RADS_EDIT_DETAILS: 'mutateBiRadsEditDetails',
    REMOVE_PATIENT: 'removePatient',
    PURGE_ARRAY: 'purgePatientArray',
    // GET_SELECTED_PATIENT: 'getPatient',
    // SET_CURRENT_PATIENT: 'setPatient',
    REMOVE_CURRENT_PATIENT: 'removeCurrentPatient',
    REMOVE_MUTATTION_PATIENT: 'removeMutationPatient',
    REMOVE_VISIT_DETAILS: 'removeVisitDetail',
    ADD_BI_RADS_EDIT_DETAILS: 'addBiRadsEditDetails',
    SET_INITIAL_BIOPSY_DATA: 'setInitialBiopsyData',
    SET_INITIAL_BI_RADS_DATA: 'setInitialBiRadsData',
    SET_PATIENT_VISIT_DETAIL: 'setPatientVisiDetails',
    REMOVE_PATIENT_VISIT_DETAIL: 'removePatientVisiDetails',
    SET_PATIENT_EDITED_ID: 'setPatientEditedId',
    MUT_VISIT_DETAIL_FILTER_ACTION: 'mutateVisitDetailFilter',
    EDIT_PATIENT_VISIT_FLAGGED_DATA_ACTION: 'editPatientVisitFlaggedData',
    MUTATE_ADD_DOCUMENT: 'mutateAddDocument',
    MUTATE_REMOVE_DOCUMENT: 'mutateRemoveDocument',
    MUTATE_DOCUMENT_ERROR: 'mutateDocumentError',
  },
  DOCTOR: {
    SET_DOCTOR_ARRAY: 'setDoctorArray',
    REMOVE_DOCTOR_ARRAY: 'removeDoctorArray',
    GET_DOCTOR_DETAIL: 'getDoctorDetail',
    GET_CURRENT_DOCTOR_DETAIL: 'getCurrentDoctorDetail',
    SELECTED_DOCTOR_ARRAY: 'selectDOctorArray',
    REMOVE_SELECTED_DOCTOR_ARRAY: 'removeDoctorArray',
    SELECTED_DOCTOR_ARRAY_MUTATION: 'mutateDoctorArray',
    MUTATE_REMOVE_USER_ID: 'mutateDOcotrsFilter',
    REMOVE_SELECTED_DOCTOR_ARRAY_MUTATION: 'removeMutateDoctorArray',
  },
  TABLE: {
    REMOVE_TABLE_STAT: 'removeTableStat',
    SET_TABLE_STAT: 'addTableStat',
  },
  FILTER: {
    REMOVE_FILTER_STAT: 'removeFilter',
    SET_FILTER_STAT: 'addFilter',
  },
  MOMENTJS: {
    YYYY_MM_DD: 'YYYY-MM-DD',
  },
  MODAL: {
    SET_MODAL_ACTION: 'setModal',
    REMOVE_MODAL_ACTION: 'removeModal',
    MUTATE_MODAL: 'mutateModal',
    REMOVE_MUTATE_MODAL: 'removeMutateModal',
  },
};

export default localVariable;
